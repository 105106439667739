import classes from "./turnierregeln.module.css";

export const Turnierregeln: React.FC = () => {
  return (
    <div className={classes.turnierregeln}>
      <div className={classes.heading}>
        <h1>Turnierregeln</h1>
      </div>
      <div className={classes.content}>
        <h4>Modus:</h4>
        <p>
          Gespielt 5+1. Ein Team besteht aus maximal 12 Spielern. Gespielt wird
          an drei Vorrundentage und dem Finaltag. Am Vorrundentag werden Teams
          in Gruppen unterteilt. Zwei Gruppen werden Parallel gespielt. Die
          Kreuzspiele und Entscheidungsspiel finden direkt am Qualifikationstag
          statt. Ein Team aus zwei Vorrundengruppen qualifiziert sich somit für
          das Finale. Am Finaltag sind dann maximal 12 Mannschaften
          qualifiziert. In der KO Runde wird bei Gleichstand eine ganze Minute
          gespielt, wobei die Mannschaften immer einen Spieler vom Feld nehmen
          müssen. Wechsel sind dann nicht mehr erlaubt. Nach der Minute wird mit
          Anstoß weitergespielt. Der Ballbesitz wechselt jede Minute.
        </p>
        <h4>Spielzeit:</h4>
        <p>
          Der Spielzeit beträgt 12 Minuten am Qualifikationstag und 15 Minuten
          am Finaltag. Das Finale wird in zweimal 10 Minuten gespielt. Die
          letzte Minute ist Nettospielzeit.
        </p>
        <h4>Regeln:</h4>
        <p>
          Es wird Hallenfußball mit Bande gespielt. Das Feld ist mit Banden
          umrandet. Der Belag ist Kunstrasen. Out und Ecken werden mit dem Fuß
          gespielt. Es ist somit indirekter Freistoß. Gelbe Karte: Verwarnung.
          Dritte gelbe Karte in der Qualifikation bedeutet ein Spiel Sperre.
          Gelb-Rote Karte: Der Spieler kann am Spiel nicht mehr teilnehmen. Sein
          Team muss das Spiel in Unterzahl beenden. Am Finaltag werden die
          gelben Karten gestrichen. Rote Karte: Der Spieler ist für das Spiel in
          dem er die Karte bekommen hat, sowie mindestens ein weiteres Spiel
          gesperrt.
        </p>
        <h4>Wechsel:</h4>
        <p>
          Der Wechsel ist fliegend. Möchte man den gesamten Block wechseln, ist
          das nur bei eigenem Ballbesitz und Spielunterbrechung möglich.
        </p>
        <h4>Ausschluss:</h4>
        <p>
          Bei Schiedsrichterbeleidigung oder Beleidigung der Organisatoren wird
          der Spieler oder die betroffenen Personen aus dem Turnier
          ausgeschlossen. Bei Raufhandel, Schlägerei oder versuchter
          Rudelbildung wird das Team aus dem Event suspendiert.
        </p>
      </div>
    </div>
  );
};
